.App {
  text-align: center;
}

:root{
  --primary-color: #7d69d9;
    --primary-color-2: #c37ede;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.brand-type-container {
  margin-top: 1rem;
  padding-top: 1rem;
  height: 20rem;
  text-align: center;
  /* background-color: #ebf6ff; */
  padding-left: 1rem;
}
.brand-container-title {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: left;
}

.brand-container-subtitle {
  color: black;
  font-weight: 500;
}
.brand-img-wrapper {
  border: 1px solid grey;
  width: 15rem;
  height: 15rem;
  margin-left: 2rem;
  border-radius: 50%;
}
.brand-img {
  height: 15rem;
  width: 15rem;
  object-fit: cover;
}
.device-category {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  padding: 1rem;
  padding-bottom: 10px;
  width: 170px;
  cursor: pointer;
  -webkit-transition: all 0.1s 0;
}
.type-wrapper {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.repair-wrapper {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}
.repair-box img {
  height: 17rem;
}
.repair-box h5 {
  text-align: center;
  color: #439a97;
}

.repair-box {
  cursor: pointer;
  border: 1px solid #e9e3e3;
  padding-bottom: 0.5rem;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0); /* Initial shadow with zero blur */
  transition: all 0.5s ease; /* Add a transition for the box-shadow property */
}
.repair-box:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.repair-form {
  margin-top: 2rem;

}
.repair-form h5 {
  color: #439a97;
}
.repair-form h4 {
  margin-top: 1rem;
  color: #439a97;
}
.ticket-container {
  padding: 2rem;
  margin-top: 4rem;
  width: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35) !important;
}

@media screen and (max-width:650px) {
  .ticket-container {
    padding: 1rem;
    margin-top: 4rem;
    width: 100% !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35) !important;
  }
}

.btn {
  background: linear-gradient(90deg,var(--primary-color) 0%,var(--primary-color-2) 100%);
  /* #0078d7 */
  color: #fff;
  font-size: 16px;
  width: 83px;
  height: 35px;
  border-radius: 4px;
  margin: 5px;
  margin-top: 10px;
}

.btn1 {
  background: linear-gradient(90deg,var(--primary-color) 0%,var(--primary-color-2) 100%);
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
  padding: 0.7rem;
}

.bgcolor{
  background: linear-gradient(90deg,var(--primary-color) 0%,var(--primary-color-2) 100%);
  color: #fff;
  padding: 0.5rem 1rem;
  
  border-radius: 5px;
}

.css-cydfgr[data-status="active"] {
  border-color: rgba(189, 124, 221, 0.933) !important;
}

.css-cydfgr[data-status="complete"] {
  background: linear-gradient(90deg,var(--primary-color) 0%,var(--primary-color-2) 100%) !important;
}

.css-4t6ey5[data-status="complete"] {
  background: linear-gradient(90deg,var(--primary-color) 0%,var(--primary-color-2) 100%) !important;
}

/* <----------Responsive------------> */

@media screen and (max-width: 609px) {
  .stepperbox {
    display: none !important;
  }
  .brand-device-title {
    font-size: 1.8rem !important;
  }

  .device-category {
    width: 130px !important;
  }
}

@media screen and (max-width: 501px) {
  .brand-container-title {
    font-size: 15px !important;
    text-align: center;
  }

  .brand-container-subtitle {
    font-size: 12px !important;
    text-align: center;
  }
}
.form-question {
  font-size: 17px;
  color: #222;
  font-weight: bold;
}
.valuation-btn {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.valuation-btn_button {
  width: 5rem;
  color: #888;
  border-radius: 5px;
  background: #fff;
  font-size: 15px;
  border: 1px solid #ccc;
}
.form-layout {
  box-shadow: rgb(204, 204, 204) 2px 2px 4px 2px;
  height: 20%;
  margin: 0 auto;
  width: 50%;
  padding: 1rem 2rem;
}
.form-heading {
  font-size: 20px;
 margin-top: 2rem;
  color: black;
  font-weight: bold;
  text-shadow: 1px 1px 5px #ddd;
}

@media screen and (max-width:268px) {
  .form-heading {
    font-size: 15px;
  }
}

.navbutton{
  transition: 0.2s all linear;
}

.navbutton:hover{
  color: rgba(189, 124, 221, 0.933);
}

input{
  outline: none;
}

.input-container{
  border: 1px solid rgba(189, 124, 221, 0.933) ;
  border-radius: 3px;
  position: relative;
}
.input-container svg{
  position: absolute;
  right: 8px;
  top: 12px;
  cursor: pointer;
  font-size: 12px;
  color: purple;
}

